import React from 'react'
import { useState, useEffect } from 'react'
import { contentfulImageLoader } from '@/utils/ImageLoaders'
import {
  Asset,
  ComponentImageGallerySlideItem,
  Maybe,
} from 'types/generated/contentful-types'
import { Button } from '@/components/ui/Buttons'
import { RichTextContent } from '@/components/design/RichTextContent'
import Image from 'next/image'
import ForaSwiper from '../swiper/ForaSwiper'
import styles from '../swiper/components/image-gallery.module.css'
import { imageLoader } from '@/utils/ImageLoaders'
import { ImageGalleryModal } from '../hotels/HotelImageGallery'
import { ButtonLink } from '../ui/ButtonLinks'

interface ImageGalleryHeaderProps {
  additionalClasses: string
  setShowModal: (showModal: boolean) => void
  title: Maybe<string> | undefined
}

const ImageGalleryHeader = ({
  additionalClasses,
  setShowModal,
  title,
}: ImageGalleryHeaderProps) => {
  return (
    <div
      data-name="image-gallery-modal-header"
      className={`flex flex-row justify-between ${additionalClasses}`}
    >
      <h3 className="text-2xl text-sand">{title}</h3>
      <div
        tabIndex={0}
        role="button"
        className="cursor-pointer my-auto w-[18px]"
        onClick={() => setShowModal(false)}
        onKeyDown={() => setShowModal(false)}
      >
        <Image
          loader={({ src }) =>
            imageLoader({
              src: src,
              width: 18,
              quality: 90,
            })
          }
          src="https://media.foratravel.com/image/upload/v1727800049/icon-close-sand_bdiu0d.svg"
          alt="Icon Close"
          width={18}
          height={18}
          layout="responsive"
          sizes="10vw"
        />
      </div>
    </div>
  )
}

interface SwiperImageGalleryProps {
  galleryImages: Maybe<Asset>[] | undefined
  isSingleImage?: boolean
  setShowModal: (showModal: boolean) => void
  title: Maybe<string> | undefined
  initialSlide?: number
}

export const HorizontalSwiperImageGallery = ({
  galleryImages,
  isSingleImage,
  setShowModal,
  title,
  initialSlide = 0,
}: SwiperImageGalleryProps): JSX.Element => {
  return (
    <section data-name="image-gallery-horizontal-swiper" className="w-full">
      <ImageGalleryHeader
        additionalClasses="px-16 mb-8 max-w-[80%] 3xl:max-w-[60%] mx-auto"
        setShowModal={setShowModal}
        title={title}
      />
      <div className="w-full max-w-[80%] 3xl:max-w-[60%] mx-auto">
        <ForaSwiper
          className={styles.foraSwiper}
          params={{
            centeredSlides: true,
            centeredSlidesBounds: true,
            navigation: isSingleImage ? false : true,
            pagination: false,
            keyboard: true,
            slidesPerView: 1,
            initialSlide,
          }}
        >
          {galleryImages &&
            galleryImages
              .filter((item) => item)
              .map((img, i) => {
                return (
                  <div className="px-16" key={`Image_${i}`}>
                    <Image
                      loader={() =>
                        contentfulImageLoader({
                          src: img?.url ?? '',
                          width: 1104,
                          height: 800,
                          quality: 90,
                          fit: 'fill',
                          format: 'webp',
                        })
                      }
                      src={img?.url ?? ''}
                      alt={img?.description || `${title}`}
                      layout="responsive"
                      objectFit="contain"
                      width={384}
                      height={256}
                      sizes="40vw"
                    />
                  </div>
                )
              })}
        </ForaSwiper>
      </div>
    </section>
  )
}

export const VerticalSwiperImageGallery = ({
  galleryImages,
  setShowModal,
  title,
}: SwiperImageGalleryProps): JSX.Element => {
  return (
    <section
      data-name="image-gallery-vertical-swiper"
      className="w-full max-h-[100%] max-w-[1400px] px-4"
    >
      <ImageGalleryHeader
        additionalClasses="fixed z-50 top-0 left-0 w-full bg-black px-4 py-2.5"
        setShowModal={setShowModal}
        title={title}
      />
      <div className="self-center inline-block max-h-[100%] w-full max-w-[2190px] mt-[6px] sm:mt-[45px]">
        <ForaSwiper
          className={`${styles.foraSwiper} pointer-events-none`}
          params={{
            direction: 'vertical',
            navigation: false,
            pagination: false,
            slidesPerView: 'auto',
            spaceBetween: 16,
          }}
        >
          {galleryImages &&
            galleryImages
              .filter((item) => item)
              .map((img, i) => {
                return (
                  <div key={`Image_${i}`}>
                    <Image
                      loader={() =>
                        contentfulImageLoader({
                          src: img?.url ?? '',
                          width: 1104,
                          height: 800,
                          quality: 90,
                          fit: 'fill',
                          format: 'webp',
                        })
                      }
                      src={img?.url ?? ''}
                      alt={img?.description || `${title}`}
                      layout="responsive"
                      objectFit="contain"
                      width={384}
                      height={256}
                      sizes="40vw"
                      data-name="gallery-image"
                    />
                  </div>
                )
              })}
        </ForaSwiper>
      </div>
    </section>
  )
}

export default function ImageGalleryCarouselCard({
  ctaText,
  description,
  title,
  featuredImage,
  imagesCollection,
  cloudinaryFeaturedImage,
  cloudinaryImages,
  url,
}: ComponentImageGallerySlideItem): JSX.Element {
  const [showModal, setShowModal] = useState<boolean>(false)
  const galleryImages =
    cloudinaryImages?.length > 0 ? cloudinaryImages : imagesCollection?.items

  useEffect(() => {
    showModal
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset')
  }, [showModal])

  return (
    <div data-name="image-gallery-item-container">
      <div className="pb-2 md:pb-3">
        <Image
          className="h-56 lg:h-64"
          src={
            cloudinaryFeaturedImage?.[0]?.secure_url ?? featuredImage?.url ?? ''
          }
          data-src={
            cloudinaryFeaturedImage?.[0]?.secure_url ?? featuredImage?.url ?? ''
          }
          alt={title || ''}
          width={384}
          height={256}
          quality={90}
          style={{ objectFit: 'cover' }}
          data-name="featured-gallery-image"
        />
      </div>
      <h3 data-name="image-gallery-title" className="pb-2 fora-text-h6 md:pb-3">
        {title}
      </h3>
      {description?.json && (
        <div
          data-name="image-gallery-description"
          className="pb-2 mb-0 font-serif text-sm md:pb-3 md:text-lg"
        >
          <RichTextContent richText={description.json} />
        </div>
      )}
      <div className="pb-2">
        {url ? (
          <ButtonLink
            text={ctaText ?? ''}
            target="_self"
            href={url}
            theme="secondary"
          />
        ) : (
          <Button
            text={ctaText ?? ''}
            theme="transparent"
            isDisabled={false}
            buttonFn={() => setShowModal(true)}
          />
        )}
      </div>
      {showModal && (
        <ImageGalleryModal
          title={title}
          imageGallery={galleryImages}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </div>
  )
}
