import { ButtonLink } from '@/components/ui/ButtonLinks'
import { ComponentCtaBanner } from 'types/generated/contentful-types'
import { RichTextContent } from '../design/RichTextContent'
import Form, { FormProps } from './Forms'

export default function CtaBanner({
  heading,
  teaserCopy,
  ctaText,
  ctaDestination,
  urlTarget,
  zapierId,
  includeForm,
  formType,
  successText,
}: ComponentCtaBanner): JSX.Element {
  return (
    <>
      <div className="p-6 mt-12 text-center bg-shell lg:mt-22 lg:px-14 lg:py-12 lg:p-0">
        <h2 className="fora-text-h3">{heading}</h2>
        <div>
          <p className="max-w-xl m-auto my-4 cta-banner-teaser-copy lg:my-8 text-darkStone">
            <RichTextContent
              richText={{ ...teaserCopy, jotformId: zapierId }}
            />
          </p>
        </div>
        {includeForm ? (
          <div className="max-w-xl m-auto my-6">
            <Form
              successText={successText}
              {...({
                formId: formType,
                jotformId: zapierId,
                buttonText: ctaText,
                conversionComponentName: 'CTA Banner',
              } as unknown as FormProps)}
            />
          </div>
        ) : (
          <div className="flex justify-center">
            <ButtonLink
              href={ctaDestination ?? ''}
              target={(urlTarget as '_self' | '_blank' | undefined) || '_self'}
              text={ctaText ?? ''}
              theme="primary"
            />
          </div>
        )}
      </div>
    </>
  )
}
