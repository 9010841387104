import Image from 'next/image'
import ReactModal from 'react-modal'
import { isLgPlus, isMobile } from '@/utils/Helpers'
import {
  HorizontalSwiperImageGallery,
  VerticalSwiperImageGallery,
} from '../pageBlocks/ImageGalleryCarouselCard'
import useBreakpoint from 'hooks/useBreakpoints'
import { useState } from 'react'
export const HotelImageGallery = ({
  showModal,
  setShowModal,
  title,
  imageGallery,
}) => {
  const breakpoint = useBreakpoint()
  const mobile = isMobile(breakpoint)
  const [showGallerySlider, setShowGallerySlider] = useState<boolean>(false)
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0)

  const imageTriads = imageGallery?.reduce(
    (r, e, i) => (i % 3 ? r[r.length - 1].push(e) : r.push([e])) && r,
    []
  )

  const handleDetailImage = (image) => {
    const selectedIndex = imageGallery.findIndex(
      (x) => x.public_id === image.public_id
    )
    setSelectedImageIndex(selectedIndex)
    !mobile && setShowGallerySlider(true)
  }

  if (typeof window === 'undefined') {
    return null
  }

  return (
    <ReactModal
      closeTimeoutMS={200}
      appElement={document.body}
      isOpen={showModal}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={false}
      overlayClassName="hotel-gallery-overlay"
      className="hotel-gallery-content"
    >
      <div
        className="flex items-center gap-x-2 mb-6 md:mb-[60px]"
        onClick={() => setShowModal(false)}
        onKeyDown={() => setShowModal(false)}
        role="button"
        tabIndex={0}
      >
        <Image
          src="https://media.foratravel.com/image/upload/v1723141712/CaretCircleLeft_txng0a.svg"
          alt="circle back icon"
          width={32}
          height={32}
        />
        <span className="md:fora-text-h7 fora-text-h8">{title}</span>
      </div>
      <div className="max-w-[728px] m-auto">
        <h4 className="mb-3 md:mb-8 fora-text-h4">All Photos</h4>
        {mobile ? (
          <>
            {imageGallery?.map((image, idx) => (
              <div key={`image-${idx}`} className="mb-2">
                <div
                  className="w-full h-[216px] md:h-[424px] relative mb-2"
                  onClick={() => handleDetailImage(image)}
                  onKeyDown={() => handleDetailImage(image)}
                  role="button"
                  tabIndex={0}
                >
                  <Image
                    src={image.secure_url}
                    alt="Hotel image"
                    fill
                    objectFit="cover"
                    objectPosition="center"
                  />
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            {imageTriads &&
              imageTriads.map((triad, idx) => (
                <div key={`triad-${idx}`} className="mb-2">
                  <div
                    className="w-full h-[300px] md:h-[424px] relative mb-2"
                    onClick={() => handleDetailImage(triad[0])}
                    onKeyDown={() => handleDetailImage(triad[0])}
                    role="button"
                    tabIndex={0}
                  >
                    <Image
                      src={triad[0].secure_url}
                      alt="Hotel image"
                      fill
                      objectFit="cover"
                      objectPosition="center"
                    />
                  </div>
                  <div className="flex gap-x-2">
                    {triad.slice(1).map((item, idx) => (
                      <div
                        key={`image-${idx}`}
                        className="relative flex-grow"
                        onClick={() => handleDetailImage(item)}
                        onKeyDown={() => handleDetailImage(item)}
                        role="button"
                        tabIndex={0}
                      >
                        <Image
                          src={item.secure_url}
                          width={728}
                          height={420}
                          alt="Hotel image"
                          className="h-full"
                          style={{
                            objectFit: 'cover',
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
      {showGallerySlider && (
        <ImageGalleryModal
          title={title}
          imageGallery={imageGallery}
          showModal={showGallerySlider}
          setShowModal={setShowGallerySlider}
          initialSlide={selectedImageIndex}
        />
      )}
    </ReactModal>
  )
}

export const ImageGalleryModal = ({
  showModal,
  setShowModal,
  imageGallery,
  title,
  initialSlide = 0,
}) => {
  const breakpoint = useBreakpoint()
  const isLargeScreen = isLgPlus(breakpoint)
  const isSingleImage = imageGallery?.length === 1

  return (
    <ReactModal
      closeTimeoutMS={200}
      appElement={document.body}
      isOpen={showModal}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={false}
      overlayClassName="image-gallery-overlay"
      style={{
        content: {
          border: 'none',
          outline: 'none',
          backgroundColor: 'transparent',
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: 'center',
          alignItems: 'center',
          inset: '0px',
          width: '100%',
          padding: '0px',
        },
      }}
    >
      {isLargeScreen ? (
        <HorizontalSwiperImageGallery
          galleryImages={imageGallery}
          isSingleImage={isSingleImage}
          setShowModal={setShowModal}
          title={title}
          initialSlide={initialSlide}
        />
      ) : (
        <VerticalSwiperImageGallery
          galleryImages={imageGallery}
          setShowModal={setShowModal}
          title={title}
        />
      )}
    </ReactModal>
  )
}
